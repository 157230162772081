import styled from "styled-components";
import { Image } from "@chakra-ui/react";

interface Props {
  dark: boolean | string;
}

const Wrapper = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  a {
    background: ${({ dark }) => (dark ? "black" : "white")};
    padding: 0.75rem 2.5rem;
    border-radius: 200px;
    margin: 0 0.25em 0 0.25em;
  }
`;

export const StyledImg = styled(Image)<{ dark?: boolean }>`
  margin: 0 5px 0 5px;
  filter: ${({ dark }) => (dark ? "invert(1)" : "invert(0)")};
`;

const AppStoreIcons: React.FC<Props> = ({ dark }) => {
  return (
    <Wrapper className="AppStoreIcons" dark={dark}>
      <a
        href="https://apps.apple.com/gb/app/trim-it/id1136939981?mt=8"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledImg
          alt="App Store"
          src="/images/Apple.svg"
          dark={dark}
          width={18}
          height={18}
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.trimit&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledImg
          alt="Get it on Google Play"
          src="/images/Android.svg"
          dark={dark}
          width={18}
          height={18}
        />
      </a>
    </Wrapper>
  );
};

export default AppStoreIcons;
