import { Center, Box, Stack, Text, Flex } from "@chakra-ui/react";
import styled from "styled-components";

const Vid = styled.video`
  position: absolute;
  object-fit: cover;
  max-height: 100vh;
  height: 100%;
  filter: brightness(0.3);
  @media screen and (min-width: 1024px) {
    width: 100%;
    max-width: 50vw;
    position: relative;
  }
`;

const Quotes = (): JSX.Element => {
  return (
    <Box height="100vh" id="quotes">
      <Flex
        background="black"
        direction="row"
        justifyContent="space-between"
        height="100%"
        flexWrap="wrap"
      >
        <Center p="2rem" width={{ base: "100%", lg: "45%" }} zIndex="9999">
          <Stack
            direction="column"
            textAlign={{ base: "center", lg: "left" }}
            justifyContent={{ base: "center" }}
          >
            <Text color="white" fontSize={{ base: "55px", md: "65px" }}>
              &quot;It&apos;s like Uber but for haircuts.&quot;
            </Text>
            <Text color="white" fontSize="22px">
              - BBC
            </Text>
          </Stack>
        </Center>

        <Vid
          poster="/images/trim-grayscale.webp"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline
          src="https://trimit-app-dev-download.s3-eu-west-1.amazonaws.com/videos/clients/bri.mp4"
        />
      </Flex>
    </Box>
  );
};

export default Quotes;
