import React from "react";
import Link from "next/link";
import { Stack, Flex, Text, Box } from "@chakra-ui/react";

const Footer: React.FC = (): JSX.Element => {
  return (
    <Box as="footer" width="100%">
      <Stack direction="column" alignItems="flex-start" width="100%">
        {/* <Flex width="100%" px="2rem" background="#F9F9F9" pt="2rem">
          <Link href="/">
            <a>
              <Image
                src="/images/TRIM-IT_LOGO.svg"
                width={76}
                height={60}
                loading="eager"
              />
            </a>
          </Link>
        </Flex> */}
        {/* <Flex
          justifyContent="space-between"
          width="100%"
          background="#F9F9F9"
          flexWrap="wrap"
          padding="2rem"
          mt="0 !important"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Stack direction="column" pb="2rem">
            <Text fontSize="16px">Are you a barber?</Text>
            <Button
              color="white"
              bg="black"
              maxWidth="140px"
              onClick={() => {
                window.scrollTo(0, 0);
                router.push("/powered-by");
              }}
            >
              Learn more
            </Button>
          </Stack>
          <Stack
            direction="column"
            pb="2rem"
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <Text fontSize="16px">Download on iOS & Android</Text>
            <AppButtons colorScheme="dark" />
          </Stack>

          <Stack
            direction="column"
            pb="2rem"
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <Text fontSize="16px">Follow us</Text>
            <Flex
              justifyContent="space-between"
              maxWidth="120px"
              alignItems="center"
            >
              <LinkBase href="https://www.facebook.com/trimitapp/">
                <FbIcon style={{ marginRight: "0.5em" }} />
              </LinkBase>
              <LinkBase href="https://www.instagram.com/trimit/?hl=en">
                <InstaIcon style={{ marginRight: "0.5em" }} />
              </LinkBase>
              <LinkBase href="https://twitter.com/trimit_app?lang=en">
                <TwitterIcon />
              </LinkBase>
            </Flex>
          </Stack>
        </Flex> */}
        <Flex
          justifyContent="flex-start"
          padding="2rem"
          background="black"
          width="100%"
          className="footer_row"
        >
          <Flex
            direction={{ base: "column-reverse", md: "row" }}
            justifyContent="flex-start"
            alignItems={{ base: "flex-start", md: "center" }}
          >
            <Text
              color="#FFFFFF"
              fontSize="16px"
              mr="1rem"
              pt={{ base: "1rem", md: "0" }}
            >
              &copy; {new Date().getFullYear()} TRIM-IT Services LTD.
            </Text>
            <Text
              color="#FFFFFF"
              fontSize="16px"
              opacity="0.4"
              pr="1rem"
              py={{ sm: ".25rem" }}
            >
              <Link href="/privacy-policy">
                <a>Privacy Policy</a>
              </Link>
            </Text>
            <Text
              color="#FFFFFF"
              opacity="0.4"
              fontSize="16px"
              pr="2rem"
              py="0.5rem"
            >
              <Link href="/terms-of-service">
                <a>Terms of Service</a>
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
};

export default Footer;
