import { useEffect } from "react";
import ReactGA from "react-ga";
import Head from "next/head";
import Home from "../components/pages/Home";
import { pageview } from "../lib/fpixel";

const Homepage = (): JSX.Element => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    pageview(); // fbpixel page view
  }, []);

  return (
    <>
      <Head>
        <title>TRIM-IT Mobile Barbershops</title>
        <meta name="description" content="Book a barber directly to you." />
      </Head>
      <Home type={"customer"} />
      {/* <RegisterLocationModal isOpen={showRegisterModal} /> */}
      {/* <WelcomeModal
        onOpen={() => setShowRegisterModal(true)}
        setType={setType}
      /> */}
    </>
  );
};

export default Homepage;
