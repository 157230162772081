import Link from "next/link";

export const LinkBase = ({ children, href }) => (
  <Link href={href}>
    <a rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  </Link>
);

export const IOSLink: React.FC = ({ children }) => (
  <Link href="https://apps.apple.com/gb/app/trim-it/id1136939981?mt=8">
    <a rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  </Link>
);

export const AndroidLink: React.FC = ({ children }) => (
  <Link href="https://play.google.com/store/apps/details?id=com.trimit&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
    <a rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  </Link>
);

export const FacebookLink = ({ children }) => (
  <LinkBase href="https://www.facebook.com/trimitapp/">{children}</LinkBase>
);
