import { Center, Stack, Image, Box, Button } from "@chakra-ui/react";
import styled from "styled-components";
import useViewport from "../../../../hooks/useWindowSize";
import ArrowIcon from "../../Franchise/svg/arrow.svg";
import { BgVideo } from "../styled";
import NextImage from "next/image";

// const Wrapper = styled(Box)`
//   @media screen and (min-width: 600px) {
//     .button-stack {
//       display: none;
//     }
//   }
// `;

const DesktopWrapper = styled(Box)`
  @media screen and (max-width: 599px) {
    .website-button {
      display: none;
    }
  }

  margin-top: 2rem;
`;

const StyledImage = styled.nav`
  filter: invert(1);
`;

const Img = styled(Image)`
  position: relative;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;

  filter: brightness(0.6);
`;

const Intro = (): JSX.Element => {
  const { width } = useViewport();
  const isMobile = width <= 768;

  return (
    <Center height="100vh" id="intro">
      {isMobile ? (
        <Img
          src="/images/barber-still-intro.webp"
          layout="responsive"
          alt="Trimit intro"
        />
      ) : (
        <BgVideo
          poster="/images/intro-still.webp"
          height="100%"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline
          preload="auto"
          src="https://trimit-app-dev-download.s3-eu-west-1.amazonaws.com/videos/homepage-header.mp4"
        />
      )}
      <Stack
        position="absolute"
        textAlign="center"
        alignItems="center"
        left="auto"
      >
        {/* <Heading
          color="white"
          fontSize={{ base: "55px", md: "70px", lg: "95px" }}
          maxWidth={{ base: "85%", lg: "100%" }}
          minWidth="212px"
          textAlign="center"
          mt={{ base: "0", md: "3rem" }}
        >
          TRIM-IT
        </Heading> */}
        <StyledImage>
          <NextImage
            src="/images/TRIM-IT_LOGO.svg"
            alt="TRIM-IT logo"
            height={110}
            width={145}
            priority
            className="light"
          />
        </StyledImage>
        {/* <Wrapper>
          <Stack direction="column" className="button-stack">
            <Text color="white" fontSize="18px" pb="1rem">
              Download on iOS & Android.
            </Text>
            <AppButtons colorScheme="light" />
          </Stack>
        </Wrapper> */}

        <DesktopWrapper>
          <Button
            className="button-stack"
            filter="invert(1)"
            border="none"
            zIndex="99"
            onClick={() => {
              const elmnt = document.getElementById("carousel");
              elmnt?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
          >
            <ArrowIcon />
          </Button>
          {/* <Link href={"https://booking.trimit.app/"}>
            <Button
              className="website-button"
              color="white"
              width={300}
              zIndex="99"
            >
              Click here
            </Button>
          </Link> */}
        </DesktopWrapper>

        {/* <Wrapper>
          <Button
            className="button-stack"
            filter="invert(1)"
            border="none"
            zIndex="99"
            onClick={() => {
              const elmnt = document.getElementById("carousel");
              elmnt?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
          >
            <ArrowIcon />
          </Button>
        </Wrapper> */}
      </Stack>
    </Center>
  );
};

export default Intro;
