export const homePage = {
  intro: "light",
  customer: "dark",
  franchise: "dark",
  carousel: "light",
  quotes: "light",
  moneymaker: "light",
  press: "dark",
};

export const poweredByPage = {
  hero: "light",
  editor: "dark",
  journey: "dark",
  featurecarousel: "dark",
  customisation: "dark",
  aboutvan: "dark",
  timeline: "light",
  interest: "dark",
};
