import { Stack, Heading, Text, Center, Flex, Badge } from "@chakra-ui/react";
import Image from "next/image";
import styled from "styled-components";
import { LinkBase } from "../../../Links";

const StyledImage = styled(Image)`
  padding: 10px 15px !important;
  filter: brightness(0);
`;

const Press = (): JSX.Element => (
  <Center py="8rem" px={{ base: "2rem", md: "3rem" }} id="press">
    <Flex direction={{ base: "column", md: "row" }} alignItems="center">
      <Stack
        direction="column"
        alignItems={{ base: "center", md: "flex-start" }}
        py="2rem"
        px="2rem"
      >
        <Text>
          <Badge
            ml="1"
            fontSize="0.8em"
            colorScheme="green"
            color="#00B5A3"
            borderRadius="30px"
            px="10px"
          >
            PRESS
          </Badge>
        </Text>
        <Heading
          maxWidth="480px"
          textAlign={{ base: "center", md: "left" }}
          fontSize={{ base: "45px", md: "50px" }}
        >
          Hear what they&apos;re saying about us.
        </Heading>
      </Stack>
      <Flex
        flexWrap="wrap"
        maxWidth={{ base: "400px" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <LinkBase href="https://www.forbes.com/sites/tommywilliams1/2021/03/11/meet-trim-it-the-mobile-barbershop-business-that-has-raised-11m-to-transform-the-industry/?sh=31983f6731c8">
          <StyledImage
            src="/images/forbes-logo.svg"
            width={140}
            height={105}
            alt="Forbes"
          />
        </LinkBase>
        <LinkBase href="https://www.insider.com/how-darren-tenkorang-started-own-uber-like-barber-business-2019-6">
          <StyledImage
            src="/images/press/bi.svg"
            width={120}
            height={40}
            alt="Business insider"
          />
        </LinkBase>
        <LinkBase href="https://www.standard.co.uk/news/london/the-uberstyle-mobile-barber-shop-delivering-haircuts-to-london-doorsteps-a4120686.html">
          <StyledImage
            src="/images/press/es.svg"
            width={140}
            height={50}
            alt="Evening standard"
          />
        </LinkBase>
        <LinkBase href="https://www.channel4.com/programmes/the-money-maker/on-demand/71499-004">
          <StyledImage
            src="/images/channel-four.svg"
            width={105}
            height={55}
            alt="Channel 4"
          />
        </LinkBase>
        <LinkBase href="https://www.bbc.co.uk/news/stories-47711610">
          <StyledImage
            src="/images/press/bbc.svg"
            width={120}
            height={30}
            alt="BBC"
          />
        </LinkBase>
        <LinkBase href="https://sg.finance.yahoo.com/amphtml/news/mobile-barber-cuts-dash-afro-caribbean-londoners-095545996.html">
          <StyledImage
            src="/images/press/yahoo.svg"
            width={110}
            height={50}
            alt="Yahoo"
          />
        </LinkBase>
      </Flex>
    </Flex>
  </Center>
);

export default Press;
