import styled from "styled-components";
import { Image } from "@chakra-ui/react";

export const BgVideo = styled.video`
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;

  height: 100vh;
  width: 100vw;
  object-fit: cover;
`;

export const BgImage = styled(Image)`
  position: relative;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;

  filter: brightness(0.6);
`;

export const HomeWrapper = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  section {
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 767px) {
    section {
      justify-content: space-between;
      text-align: left;
    }
    .section-press {
      margin-bottom: 2rem !important;
      .TextColumn {
        width: 100%;
        max-width: 500px;
      }
    }

    .scribble {
      position: absolute;
      z-index: 1;
    }

    .screen {
      z-index: 9999;
    }

    .NewToTrimIT {
      justify-content: space-between;
      text-align: left;
    }

    .icon-smartphone {
      display: block;
    }

    .TextColumn {
      max-width: 400px;
      padding-right: 2rem;
    }

    .press-text {
      display: block;
    }
  }

  @media screen and (min-width: 1024px) {
    .section-press {
      max-width: 1068px;
      margin: 0 auto;
      border-radius: 8px;
      -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.31);
      -moz-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.31);
      box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.31);
    }
  }
`;
