import styled from "styled-components";

export interface HeaderProps {
  mobile?: boolean;
  open?: boolean;
}

export interface LinkItemProps {
  active?: boolean;
}

export const StyledHeader = styled.header<HeaderProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid var(--green);
  background: transparent;

  .dark {
    /* menu */
    span {
      background: black;
    }
    /* Logo */

    /* filter: invert(0) !important; */
    /* if the menu is not open then impportant */
    filter: ${({ open }) =>
      open ? "invert(1)!important" : "invert(0)!important"};

    transition: filter 0.2s ease-in-out;
  }

  img {
    display: ${({ mobile }) => (mobile ? "none" : "block")};
  }

  .light {
    filter: invert(0);
    transition: filter 0.2s ease-in-out;
  }

  /* top side of the header */
  .top {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999999;
    position: fixed;
    width: 100%;
    background: ${({ open }) => (open ? "black" : "transparent")};

    nav {
      display: flex;
      li {
        list-style: none;
        margin: 0 1rem;
      }
    }

    a,
    p {
      margin: 0;
      cursor: pointer;
    }

    img {
      z-index: 9999999;
      cursor: pointer;
      filter: invert(1);
    }

    .MenuIcon {
      filter: ${({ open }) => (open ? "invert(0) !important" : "")};
    }

    .top-links {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (min-width: 768px) {
    .top {
      padding: 2rem 2rem 2rem 2rem;

      position: fixed;

      width: 100%;
      height: 96px;
    }
    .bottom {
      position: fixed;
      z-index: 99999999999999;
      width: 100%;
      a,
      p {
        font-size: 20px;
        color: white;
      }
    }
  }
`;

export const StyledMobileNav = styled.nav`
  z-index: 99999999999;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: fixed;
    z-index: 9999999;
    background: black;

    height: 100%;

    top: 6rem;
    width: 100%;
    padding-bottom: 4rem;
    padding-top: 1rem;
  }

  li {
    color: white;
    margin: 1rem 2rem;
    list-style: none;
  }

  a {
    font-size: 25px;
    font-weight: 400;
    color: white;
  }
`;

export const StyledNav = styled.nav`
  /* background */
  background: url(/images/navigation-background.png);
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: black;
  position: fixed;
  z-index: 999999999;

  padding: 3rem 1rem 3rem 2rem;
  height: 270px;
  position: absolute;
  left: 0;
  z-index: 99999999;
  width: 100%;
  margin: 0 auto;

  /* list link */
  ul {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;
    border-left: 2px solid;
    border-image-source: linear-gradient(180deg, #00b5a3 0%, #fdc12d 100%);
    border-image-slice: 1;
    padding-right: 1rem;
    position: fixed;

    li {
      margin-left: 1rem;
      cursor: pointer;
      text-decoration: none;
      display: flex;
      flex-direction: column;

      &:hover {
        a {
          color: var(--green);
        }
      }
    }
  }

  a {
    font-size: 14px;
    margin-top: 5px;
  }

  .Nav-image {
    border-radius: 3px;
  }
`;

export const LinkItem = styled.li<LinkItemProps>`
  list-style: none;
  a,
  p {
    color: ${(props) => (props.active ? "black" : "#818181")};
  }

  &:hover {
    a,
    p {
      color: black;
      font-weight: 500;
    }
  }
`;
