import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useViewportScroll } from "framer-motion";

import Link from "next/link";
import Image from "next/image";

import { StyledHeader } from "./styled";
import useViewPort from "../../hooks/useWindowSize";
import { homePage, poweredByPage } from "./config";

interface HeaderProps {
  name?: string | null;
  hideHeader?: boolean;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps): JSX.Element => {
  const { width } = useViewPort();
  const router = useRouter();
  const [currentStyle, setCurrentStyle] = useState("");
  const { scrollYProgress } = useViewportScroll();

  const onHomepage = router?.pathname === "/";
  const onPartnerPage = router?.pathname === "/partner";
  const onPoweredByPage = router?.pathname === "/powered-by";
  const onPoweredByEditorPage = router?.pathname === "/powered-by/editor";
  const onPoweredByLaunchPage = router?.pathname === "/powered-by/launch";

  const referral =
    router?.pathname.includes("/refer") ||
    router.pathname.includes("/referral") ||
    onPoweredByLaunchPage;

  const getCurTopElement = (pageConfig) => {
    const keys = Object.keys(pageConfig);
    for (let x = 0; x < keys.length; x++) {
      const curComponent = document
        .getElementById(keys[x])
        ?.getBoundingClientRect();
      if (curComponent && curComponent.y + curComponent.height > 50) {
        setCurrentStyle(pageConfig[keys[x]]);
        break;
      }
    }
  };

  if (referral) {
    useEffect(() => {
      setCurrentStyle("");
    }, []);
  } else {
    if (props.hideHeader) {
      useEffect(() => {
        setCurrentStyle("dark");
      }, []);
    } else {
      useEffect(() => {
        if (onPoweredByEditorPage) {
          setCurrentStyle("dark");
        }
        return scrollYProgress.onChange((v) => {
          if (onHomepage) {
            getCurTopElement(homePage);
          }
          if (onPartnerPage) {
            setCurrentStyle("dark");
            if (v >= 0.126 && v <= 0.271) {
              setCurrentStyle("");
              return;
            }
            if (v >= 0.444 && v <= 0.6908) {
              setCurrentStyle("");
              return;
            }
          }
          if (onPoweredByPage) {
            getCurTopElement(poweredByPage);
          }
        });
      }, [
        scrollYProgress,
        onHomepage,
        onPartnerPage,
        onPoweredByPage,
        onPoweredByEditorPage,
      ]);
    }
  }
  const ref = useRef<HTMLDivElement>(null);

  const [opened, toggleOpen] = useState(false);

  const BREAKPOINT_TABLET = 768;
  const isDesktop = width >= BREAKPOINT_TABLET;

  return (
    <StyledHeader mobile={!isDesktop} open={opened}>
      <div ref={ref}>
        <div className="top">
          <div className="top-links">
            <Link href="/">
              <a>
                <Image
                  src="/images/TRIM-IT_LOGO.svg"
                  alt="TRIM-IT logo"
                  height={60}
                  width={80}
                  priority
                  className={currentStyle}
                />
              </a>
            </Link>
          </div>

          {/* {!router.pathname.includes("/events") && (
            <MenuIcon
              open={opened}
              onClick={() => toggleOpen(!opened)}
              className={currentStyle}
            />
          )} */}
        </div>
        {/* <div className="bottom">
          {opened && (
            <MobileNav
              linkGroups={mobileLinks}
              onClick={() => toggleOpen(false)}
            />
          )}
        </div> */}
      </div>
    </StyledHeader>
  );
};

export default Header;
