import { Box, Stack, Text } from "@chakra-ui/react";
import Slider from "react-slick";
import styled from "styled-components";
import Image from "next/image";
import useViewPort from "../../../../hooks/useWindowSize";
import ArrowNext from "../svg/carousel-arrow.svg";
import "slick-carousel/slick/slick.css";

const Wrapper = styled(Box)`
  width: 100%;
  .chakra-stack {
    &:focus {
      outline: none !important;
      border: none !important;

      p {
        display: block;
        opacity: 1 !important;
      }
    }

    &:hover {
      p {
        transition: opacity 0.6s;
        opacity: 1;
      }
    }
  }
  img {
    margin: 0;
    padding: 0 1rem;
    margin: 0 auto;
    max-height: 600px;
    object-fit: contain;
  }
  .slick-slider {
    width: 100%;
  }

  .slick-next {
    position: absolute;
    top: 40%;
    right: 2rem;
    z-index: 99999;
  }

  .slick-prev {
    position: absolute;
    top: 40%;
    left: 2rem;
    z-index: 99999;
  }

  p {
    /* display: none; */
    opacity: 0;
    color: white;
    font-size: 16px;
  }

  .slick-current {
    p {
      display: block;
      opacity: 1;
      text-align: center;
    }
  }

  @media screen and (min-width: 768px) {
    img {
      padding: 0 1rem;
      transition: transform 0.3s;
      max-height: 600px;
      object-fit: cover;

      &:hover {
        transform: translateY(-20px) !important;
      }
    }

    .slick-slide {
      &:focus {
        outline: none;
        border: none;
      }
    }

    .slick-slider {
      height: auto;
    }

    .chakra-stack {
      &:focus {
        outline: none !important;
        border: none !important;

        p {
          /* display: block; */
        }
      }

      &:hover {
        p {
          display: block;
        }
      }
    }

    .slick-current {
      p {
        display: none;
      }
    }
  }
`;

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <ArrowNext />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        transform: "rotate(180deg)",
      }}
      onClick={onClick}
    >
      <ArrowNext />
    </div>
  );
}

const AppCarousel = (): JSX.Element => {
  const { width } = useViewPort();
  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: width < 768 ? "0px" : "0px",
    infinite: true,
    speed: 500,
    slidesToShow: width < 1024 ? 1 : 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Wrapper
      background="#0A5F6F"
      display="flex"
      maxHeight="100vh"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      padding="2rem"
      position="relative"
      id="carousel"
    >
      <Slider {...settings}>
        <Stack
          direction="column"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
        >
          <Image
            src="/images/screenshot-home.webp"
            objectFit="contain"
            width={302}
            height={537}
          />
          <Text fontSize={{ base: "18px", md: "25px" }} textAlign="center">
            Book an appointment directly to you.
          </Text>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
        >
          <Image
            src="/images/screenshot-payment.webp"
            objectFit="contain"
            width={302}
            height={537}
          />
          <Text fontSize={{ base: "18px", md: "25px" }} textAlign="center">
            Make payments in the app.
          </Text>
        </Stack>

        <Stack
          direction="column"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
        >
          <Image
            src="/images/screenshot-booking.webp"
            objectFit="contain"
            width={302}
            height={537}
          />
          <Text fontSize={{ base: "18px", md: "25px" }} textAlign="center">
            Track your barber to you.
          </Text>
        </Stack>
      </Slider>
    </Wrapper>
  );
};

export default AppCarousel;
