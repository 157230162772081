/* eslint-disable react/no-unescaped-entities */
import styled from "styled-components";
import Layout from "../../Layout";
import Intro from "./sections/Intro";
// import Franchise from "./sections/Franchise";
import AppCarousel from "./sections/AppCarousel";
import Quotes from "./sections/Quotes";
// import TheMoneyMaker from "./sections/TheMoneyMaker";
import Press from "./sections/Press";
// import Customer from "./sections/Customer";

export const BgVideo = styled.video`
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

export const BgImage = styled.img`
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100vw;
  height: 100%;
  max-height: 100vh;
  object-fit: cover;

  filter: brightness(0.6);
`;

interface Props {
  type: string;
}

const Home = (props: Props): JSX.Element => {
  return (
    <Layout>
      {/* <HomeWrapper> */}
      <Intro />
      {/* {props.type === "customer" ? <Customer /> : <Franchise />} */}
      {/* <AppIntro /> */}
      <AppCarousel />
      <Quotes />
      {/* <TheMoneyMaker /> */}
      <Press />
      {/* </HomeWrapper> */}
    </Layout>
  );
};

export default Home;
