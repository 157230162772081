import styled from "styled-components";
import Link from "next/link";
import { useRouter } from "next/router";
import { Stack, Text, Button, Box } from "@chakra-ui/react";
import { useViewportScroll } from "framer-motion";

import Header from "./Header";
import Footer from "./Footer/index";
import { useState, useEffect, ReactNode } from "react";
import AppStoreIcons from "./AppStoreIcons";
import { homePage } from "./Header/config";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .controls {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .controls {
      display: flex;
    }
  }
`;

const StickyRow = styled(Box)`
  position: fixed;
  width: 100%;
  padding: 0 2rem 2rem 2rem;
  display: flex;
  justify-content: space-between;
  z-index: 999999;
  bottom: 0;
  left: 0;

  p {
    mix-blend-mode: darken;
  }
`;

const Banner = styled(Box)`
  width: 100%;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  align-items: center;
  height: 75px;
  background-color: var(--green);
  z-index: 999999;
  position: fixed;

  @media screen and (max-width: 424px) {
    bottom: 0;
  }
`;

export const Controls = (): JSX.Element => {
  const router = useRouter();
  const [currentStyle, setCurrentStyle] = useState("");
  const [pageBottom, setPageBottom] = useState(false);
  const { scrollYProgress } = useViewportScroll();

  const notHomepage =
    router?.pathname?.includes("partner") ||
    router?.pathname.includes("investor");

  const isDark = currentStyle === "dark";

  const getCurButtomElement = (pageConfig) => {
    const keys = Object.keys(pageConfig);
    for (let x = 0; x < keys.length; x++) {
      const curComponent = document
        .getElementById(keys[x])
        ?.getBoundingClientRect();
      if (curComponent && curComponent.top + 75 <= window.innerHeight) {
        setCurrentStyle(pageConfig[keys[x]]);
      }
    }
  };

  useEffect(() => {
    return scrollYProgress.onChange((v) => {
      const curComponent = document
        .getElementById("press")
        ?.getBoundingClientRect();

      getCurButtomElement(homePage);
      if (curComponent && curComponent.top <= window.innerHeight) {
        setPageBottom(true);
      } else {
        setPageBottom(false);
      }

      return;
    });
  }, [scrollYProgress]);

  return (
    <StickyRow
      className="controls"
      display={pageBottom || notHomepage ? "none !important" : "flex"}
      flexDirection="row-reverse"
    >
      <Stack direction="column" alignItems="center">
        <Text color={currentStyle === "dark" ? "black" : "white"}>
          Download on iOS & Android
        </Text>
        <AppStoreIcons dark={currentStyle === "dark" ? true : false} />
      </Stack>
      <Stack direction="column">
        <Text color={currentStyle === "dark" ? "black" : "white"}>
          Are you a barber?
        </Text>
        <Link href="/powered-by">
          <Button
            color={currentStyle === "dark" ? "white" : "black"}
            bgColor={currentStyle === "dark" ? "black" : "white"}
            border="none"
          >
            Learn more
          </Button>
        </Link>
      </Stack>
    </StickyRow>
  );
};

const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};

const Layout = ({
  children,
  hideHeader,
}: {
  children?: ReactNode;
  hideHeader?: boolean;
}): JSX.Element => {
  // const router = useRouter();
  // const onPoweredByPage = router?.pathname === "/powered-by";
  // const onPoweredByEditorPage = router?.pathname === "/powered-by/editor";

  return (
    <Wrapper>
      <Header hideHeader={hideHeader} />
      {/* {!hideHeader && !onPoweredByPage && !onPoweredByEditorPage && (
        <Controls />
      )} */}
      {children}
      <Footer />
    </Wrapper>
  );
};

export default Layout;
