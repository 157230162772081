import React from "react";

const useViewport = (): { width: number; height: number } => {
  if (typeof window == undefined) {
    return { width: 0, height: 0 };
  }

  const [width, setWidth] = React.useState(0);
  // Add a second state variable "height" and default it to the current window height
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      // Set the height in state as well as the width
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width, height };
};

export default useViewport;
